import Permissions from 'src/security/permissions';
import config from 'src/config';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () => import('src/view/home/HomePage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change-member',
    loader: () =>
      import('src/view/auth/PasswordChangeMemberFormPage'),
    permissionRequired: permissions.passwordChangeMember,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/reporting',
    loader: () =>
      import('src/view/reporting/ReportingPage'),
    permissionRequired: permissions.reporting,
  },

  {
    path: '/affirmation',
    loader: () =>
      import(
        'src/view/affirmation/list/AffirmationListPage'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/affirmation/new',
    loader: () =>
      import(
        'src/view/affirmation/form/AffirmationFormPage'
      ),
    permissionRequired: permissions.affirmationCreate,
    exact: true,
  },
  {
    path: '/affirmation/importer',
    loader: () =>
      import(
        'src/view/affirmation/importer/AffirmationImporterPage'
      ),
    permissionRequired: permissions.affirmationImport,
    exact: true,
  },
  {
    path: '/affirmation/:id/edit',
    loader: () =>
      import(
        'src/view/affirmation/form/AffirmationFormPage'
      ),
    permissionRequired: permissions.affirmationEdit,
    exact: true,
  },
  {
    path: '/affirmation/:id',
    loader: () =>
      import(
        'src/view/affirmation/view/AffirmationViewPage'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/add-programs',
    loader: () =>
      import(
        'src/view/addPrograms/AddPrograms'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/courses',
    loader: () =>
      import(
        'src/view/courses/view/CouseListPage'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/cancelled-subscription',
    loader: () =>
      import(
        'src/view/cancelledSubscription/view/CancelledSubscription'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/courses/course-detail',
    loader: () =>
      import(
        'src/view/courses/view/CourseDetail'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/courses/edit-course',
    loader: () =>
      import(
        'src/view/courses/view/EditCoursePage'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/21-day-programs',
    loader: () =>
      import(
        'src/view/21DayPrograms/21DayPrograms'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/21-day-programs/new',
    loader: () =>
      import(
        'src/view/21DayPrograms/CreateNewProgam'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },
  {
    path: '/21-day-programs/new/day-details',
    loader: () =>
      import(
        'src/view/21DayPrograms/DayDetails'
      ),
    permissionRequired: permissions.affirmationRead,
    exact: true,
  },

  {
    path: '/affirmation-program',
    loader: () =>
      import(
        'src/view/affirmationProgram/list/AffirmationProgramListPage'
      ),
    permissionRequired: permissions.affirmationProgramRead,
    exact: true,
  },
  {
    path: '/affirmation-program/new',
    loader: () =>
      import(
        'src/view/affirmationProgram/form/AffirmationProgramFormPage'
      ),
    permissionRequired:
      permissions.affirmationProgramCreate,
    exact: true,
  },
  {
    path: '/affirmation-program/importer',
    loader: () =>
      import(
        'src/view/affirmationProgram/importer/AffirmationProgramImporterPage'
      ),
    permissionRequired:
      permissions.affirmationProgramImport,
    exact: true,
  },
  {
    path: '/affirmation-program/:id/edit',
    loader: () =>
      import(
        'src/view/affirmationProgram/form/AffirmationProgramFormPage'
      ),
    permissionRequired: permissions.affirmationProgramEdit,
    exact: true,
  },
  {
    path: '/affirmation-program/:id',
    loader: () =>
      import(
        'src/view/affirmationProgram/view/AffirmationProgramViewPage'
      ),
    permissionRequired: permissions.affirmationProgramRead,
    exact: true,
  },

  {
    path: '/sound',
    loader: () =>
      import('src/view/sound/list/SoundListPage'),
    permissionRequired: permissions.soundRead,
    exact: true,
  },
  {
    path: '/sound/new',
    loader: () =>
      import('src/view/sound/form/SoundFormPage'),
    permissionRequired: permissions.soundCreate,
    exact: true,
  },
  {
    path: '/sound/importer',
    loader: () =>
      import('src/view/sound/importer/SoundImporterPage'),
    permissionRequired: permissions.soundImport,
    exact: true,
  },
  {
    path: '/sound/:id/edit',
    loader: () =>
      import('src/view/sound/form/SoundFormPage'),
    permissionRequired: permissions.soundEdit,
    exact: true,
  },
  {
    path: '/sound/:id',
    loader: () =>
      import('src/view/sound/view/SoundViewPage'),
    permissionRequired: permissions.soundRead,
    exact: true,
  },

  {
    path: '/sound-title',
    loader: () =>
      import('src/view/soundTitle/list/SoundTitleListPage'),
    permissionRequired: permissions.soundTitleRead,
    exact: true,
  },
  {
    path: '/sound-title/new',
    loader: () =>
      import('src/view/soundTitle/form/SoundTitleFormPage'),
    permissionRequired: permissions.soundTitleCreate,
    exact: true,
  },
  {
    path: '/sound-title/importer',
    loader: () =>
      import(
        'src/view/soundTitle/importer/SoundTitleImporterPage'
      ),
    permissionRequired: permissions.soundTitleImport,
    exact: true,
  },
  {
    path: '/sound-title/:id/edit',
    loader: () =>
      import('src/view/soundTitle/form/SoundTitleFormPage'),
    permissionRequired: permissions.soundTitleEdit,
    exact: true,
  },
  {
    path: '/sound-title/:id',
    loader: () =>
      import('src/view/soundTitle/view/SoundTitleViewPage'),
    permissionRequired: permissions.soundTitleRead,
    exact: true,
  },

  {
    path: '/subscription-tier',
    loader: () =>
      import(
        'src/view/subscriptionTier/list/SubscriptionTierListPage'
      ),
    permissionRequired: permissions.subscriptionTierRead,
    exact: true,
  },
  {
    path: '/subscription-tier/new',
    loader: () =>
      import(
        'src/view/subscriptionTier/form/SubscriptionTierFormPage'
      ),
    permissionRequired: permissions.subscriptionTierCreate,
    exact: true,
  },
  {
    path: '/subscription-tier/importer',
    loader: () =>
      import(
        'src/view/subscriptionTier/importer/SubscriptionTierImporterPage'
      ),
    permissionRequired: permissions.subscriptionTierImport,
    exact: true,
  },
  {
    path: '/subscription-tier/:id/edit',
    loader: () =>
      import(
        'src/view/subscriptionTier/form/SubscriptionTierFormPage'
      ),
    permissionRequired: permissions.subscriptionTierEdit,
    exact: true,
  },
  {
    path: '/subscription-tier/:id',
    loader: () =>
      import(
        'src/view/subscriptionTier/view/SubscriptionTierViewPage'
      ),
    permissionRequired: permissions.subscriptionTierRead,
    exact: true,
  },

  {
    path: '/entitlement',
    loader: () =>
      import(
        'src/view/entitlement/list/EntitlementListPage'
      ),
    permissionRequired: permissions.entitlementRead,
    exact: true,
  },
  {
    path: '/entitlement/new',
    loader: () =>
      import(
        'src/view/entitlement/form/EntitlementFormPage'
      ),
    permissionRequired: permissions.entitlementCreate,
    exact: true,
  },
  {
    path: '/entitlement/importer',
    loader: () =>
      import(
        'src/view/entitlement/importer/EntitlementImporterPage'
      ),
    permissionRequired: permissions.entitlementImport,
    exact: true,
  },
  {
    path: '/entitlement/:id/edit',
    loader: () =>
      import(
        'src/view/entitlement/form/EntitlementFormPage'
      ),
    permissionRequired: permissions.entitlementEdit,
    exact: true,
  },
  {
    path: '/entitlement/:id',
    loader: () =>
      import(
        'src/view/entitlement/view/EntitlementViewPage'
      ),
    permissionRequired: permissions.entitlementRead,
    exact: true,
  },

  {
    path: '/affirmation-program-entitlement',
    loader: () =>
      import(
        'src/view/affirmationProgramEntitlement/list/AffirmationProgramEntitlementListPage'
      ),
    permissionRequired:
      permissions.affirmationProgramEntitlementRead,
    exact: true,
  },
  {
    path: '/affirmation-program-entitlement/new',
    loader: () =>
      import(
        'src/view/affirmationProgramEntitlement/form/AffirmationProgramEntitlementFormPage'
      ),
    permissionRequired:
      permissions.affirmationProgramEntitlementCreate,
    exact: true,
  },
  {
    path: '/affirmation-program-entitlement/importer',
    loader: () =>
      import(
        'src/view/affirmationProgramEntitlement/importer/AffirmationProgramEntitlementImporterPage'
      ),
    permissionRequired:
      permissions.affirmationProgramEntitlementImport,
    exact: true,
  },
  {
    path: '/affirmation-program-entitlement/:id/edit',
    loader: () =>
      import(
        'src/view/affirmationProgramEntitlement/form/AffirmationProgramEntitlementFormPage'
      ),
    permissionRequired:
      permissions.affirmationProgramEntitlementEdit,
    exact: true,
  },
  {
    path: '/affirmation-program-entitlement/:id',
    loader: () =>
      import(
        'src/view/affirmationProgramEntitlement/view/AffirmationProgramEntitlementViewPage'
      ),
    permissionRequired:
      permissions.affirmationProgramEntitlementRead,
    exact: true,
  },

  {
    path: '/user-entitlement',
    loader: () =>
      import(
        'src/view/userEntitlement/list/UserEntitlementListPage'
      ),
    permissionRequired: permissions.userEntitlementRead,
    exact: true,
  },
  // {
  //   path: '/cancelled-entitlement',
  //   loader: () =>
  //     import(
  //       'src/view/cancelledEntitlement/list/CancelledUserEntitlementListPage'
  //     ),
  //   permissionRequired: permissions.userEntitlementRead,
  //   exact: true,
  // },
  {
    path: '/user-entitlement/new',
    loader: () =>
      import(
        'src/view/userEntitlement/form/UserEntitlementFormPage'
      ),
    permissionRequired: permissions.userEntitlementCreate,
    exact: true,
  },
  {
    path: '/user-entitlement/importer',
    loader: () =>
      import(
        'src/view/userEntitlement/importer/UserEntitlementImporterPage'
      ),
    permissionRequired: permissions.userEntitlementImport,
    exact: true,
  },
  {
    path: '/user-entitlement/:id/edit',
    loader: () =>
      import(
        'src/view/userEntitlement/form/UserEntitlementFormPage'
      ),
    permissionRequired: permissions.userEntitlementEdit,
    exact: true,
  },
  {
    path: '/user-entitlement/:id',
    loader: () =>
      import(
        'src/view/userEntitlement/view/UserEntitlementViewPage'
      ),
    permissionRequired: permissions.userEntitlementRead,
    exact: true,
  },

  {
    path: '/usage-log',
    loader: () =>
      import('src/view/usageLog/list/UsageLogListPage'),
    permissionRequired: permissions.usageLogRead,
    exact: true,
  },
  {
    path: '/usage-log/:id',
    loader: () =>
      import('src/view/usageLog/view/UsageLogViewPage'),
    permissionRequired: permissions.usageLogRead,
    exact: true,
  },

  {
    path: '/user-affirmation-program',
    loader: () =>
      import(
        'src/view/userAffirmationProgram/list/UserAffirmationProgramListPage'
      ),
    permissionRequired:
      permissions.userAffirmationProgramRead,
    exact: true,
  },
  {
    path: '/user-affirmation-program/new',
    loader: () =>
      import(
        'src/view/userAffirmationProgram/form/UserAffirmationProgramFormPage'
      ),
    permissionRequired:
      permissions.userAffirmationProgramCreate,
    exact: true,
  },
  {
    path: '/user-affirmation-program/importer',
    loader: () =>
      import(
        'src/view/userAffirmationProgram/importer/UserAffirmationProgramImporterPage'
      ),
    permissionRequired:
      permissions.userAffirmationProgramImport,
    exact: true,
  },
  {
    path: '/user-affirmation-program/:id/edit',
    loader: () =>
      import(
        'src/view/userAffirmationProgram/form/UserAffirmationProgramFormPage'
      ),
    permissionRequired:
      permissions.userAffirmationProgramEdit,
    exact: true,
  },
  {
    path: '/user-affirmation-program/:id',
    loader: () =>
      import(
        'src/view/userAffirmationProgram/view/UserAffirmationProgramViewPage'
      ),
    permissionRequired:
      permissions.userAffirmationProgramRead,
    exact: true,
  },

  {
    path: '/enterprise-alexa-skill',
    loader: () =>
      import(
        'src/view/enterpriseAlexaSkill/list/EnterpriseAlexaSkillListPage'
      ),
    permissionRequired:
      permissions.enterpriseAlexaSkillRead,
    exact: true,
  },
  {
    path: '/enterprise-alexa-skill/new',
    loader: () =>
      import(
        'src/view/enterpriseAlexaSkill/form/EnterpriseAlexaSkillFormPage'
      ),
    permissionRequired:
      permissions.enterpriseAlexaSkillCreate,
    exact: true,
  },
  {
    path: '/enterprise-alexa-skill/importer',
    loader: () =>
      import(
        'src/view/enterpriseAlexaSkill/importer/EnterpriseAlexaSkillImporterPage'
      ),
    permissionRequired:
      permissions.enterpriseAlexaSkillImport,
    exact: true,
  },
  {
    path: '/enterprise-alexa-skill/:id/edit',
    loader: () =>
      import(
        'src/view/enterpriseAlexaSkill/form/EnterpriseAlexaSkillFormPage'
      ),
    permissionRequired:
      permissions.enterpriseAlexaSkillEdit,
    exact: true,
  },
  {
    path: '/enterprise-alexa-skill/:id',
    loader: () =>
      import(
        'src/view/enterpriseAlexaSkill/view/EnterpriseAlexaSkillViewPage'
      ),
    permissionRequired:
      permissions.enterpriseAlexaSkillRead,
    exact: true,
  },

  {
    path: '/alexa-i18n',
    loader: () =>
      import('src/view/alexaI18n/list/AlexaI18nListPage'),
    permissionRequired: permissions.alexaI18nRead,
    exact: true,
  },
  {
    path: '/alexa-i18n/new',
    loader: () =>
      import('src/view/alexaI18n/form/AlexaI18nFormPage'),
    permissionRequired: permissions.alexaI18nCreate,
    exact: true,
  },
  {
    path: '/alexa-i18n/importer',
    loader: () =>
      import(
        'src/view/alexaI18n/importer/AlexaI18nImporterPage'
      ),
    permissionRequired: permissions.alexaI18nImport,
    exact: true,
  },
  {
    path: '/alexa-i18n/:id/edit',
    loader: () =>
      import('src/view/alexaI18n/form/AlexaI18nFormPage'),
    permissionRequired: permissions.alexaI18nEdit,
    exact: true,
  },
  {
    path: '/alexa-i18n/:id',
    loader: () =>
      import('src/view/alexaI18n/view/AlexaI18nViewPage'),
    permissionRequired: permissions.alexaI18nRead,
    exact: true,
  },

  {
    path: '/wellness-check',
    loader: () =>
      import(
        'src/view/wellnessCheck/list/WellnessCheckListPage'
      ),
    permissionRequired: permissions.wellnessCheckRead,
    exact: true,
  },
  {
    path: '/wellness-check/new',
    loader: () =>
      import(
        'src/view/wellnessCheck/form/WellnessCheckFormPage'
      ),
    permissionRequired: permissions.wellnessCheckCreate,
    exact: true,
  },
  {
    path: '/wellness-check/importer',
    loader: () =>
      import(
        'src/view/wellnessCheck/importer/WellnessCheckImporterPage'
      ),
    permissionRequired: permissions.wellnessCheckImport,
    exact: true,
  },
  {
    path: '/wellness-check/:id/edit',
    loader: () =>
      import(
        'src/view/wellnessCheck/form/WellnessCheckFormPage'
      ),
    permissionRequired: permissions.wellnessCheckEdit,
    exact: true,
  },
  {
    path: '/wellness-check/:id',
    loader: () =>
      import(
        'src/view/wellnessCheck/view/WellnessCheckViewPage'
      ),
    permissionRequired: permissions.wellnessCheckRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
